import { useCssVar } from '@vueuse/core'
import {getColors} from "theme-colors";

function hexToRgb(hex: string): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        Number.parseInt(result[1], 16),
        Number.parseInt(result[2], 16),
        Number.parseInt(result[3], 16),
      ].join(' ')
    : '66 68 98'
}

export default function () {
  const container = ref()

  const primary = useCssVar('--color-primary-DEFAULT', container)
  const primary50 = useCssVar('--color-primary-50', container)
  const primary100 = useCssVar('--color-primary-100', container)
  const primary200 = useCssVar('--color-primary-200', container)
  const primary300 = useCssVar('--color-primary-300', container)
  const primary400 = useCssVar('--color-primary-400', container)
  const primary500 = useCssVar('--color-primary-500', container)
  const primary600 = useCssVar('--color-primary-600', container)
  const primary700 = useCssVar('--color-primary-700', container)
  const primary800 = useCssVar('--color-primary-800', container)
  const primary900 = useCssVar('--color-primary-900', container)
  const primary950 = useCssVar('--color-primary-950', container)

  const setColor = (color: string) => {
    const theme = getColors(color)
    primary.value = hexToRgb(color)
    primary50.value = hexToRgb(theme['50'])
    primary100.value = hexToRgb(theme['100'])
    primary200.value = hexToRgb(theme['200'])
    primary300.value = hexToRgb(theme['300'])
    primary400.value = hexToRgb(theme['400'])
    primary500.value = hexToRgb(theme['500'])
    primary600.value = hexToRgb(theme['600'])
    primary700.value = hexToRgb(theme['700'])
    primary800.value = hexToRgb(theme['800'])
    primary900.value = hexToRgb(theme['900'])
    primary950.value = hexToRgb(theme['950'])
  }


  return {
    container,
    setColor,
  }
}
